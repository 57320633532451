import {
  ATTRIBUTE_NAMES,
  PRODUCT_NATURE_PHYSICAL,
  PRODUCT_NATURE_VIRTUAL,
  PRODUCT_NATURE_VIRTUAL_NO_SN,
  PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID,
  PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
  VIRTUAL_PRODUCT_TYPE,
} from 'definitions'
import { sortByNumber } from 'helpers/Array'

const rules = 'required'

export const generateFields = props => {
  const {
    data: {
      attributes = [],
      additionalOptions = [],
      categories = [],
      customOptions = [],
      countryOfManufacture,
      description = '',
      defaultImageId,
      disabledForNotLoggedIn,
      hiddenFromNotLoggedIn,
      moq = 0,
      name = '',
      orderProcess,
      permission = {},
      ministorePermission = {},
      productCode = '',
      productNature = {},
      productSeries,
      productType,
      shippingMethod,
      shippingType,
      shortDescription = '',
      storeDisplayInd,
      tags = [],
      timePeriod,
      timePeriodUnit,
      upc = '',
      flexibleCustomOptionCategory = [],
      searchTag = [],
    } = {},
  } = props

  const isPhysicalProduct = !productNature || productNature.value === PRODUCT_NATURE_PHYSICAL

  const defaultFields = {
    additionalOptions: {
      value: additionalOptions,
    },
    attachmentUniqueId: {},
    categories: {
      value: categories,
      rules: 'required|array',
    },
    countryOfManufacture: {
      type: 'select',
      label: 'Country of Manufacture',
      value: countryOfManufacture,
    },
    customFieldsValidator: {
      validators: [requireAttributes],
      value: true,
    },
    customFields: {
      fields: getCustomFields(attributes),
    },
    customOptions: {
      type: 'array',
      value: customOptions,
    },
    description: {
      value: description,
    },
    defaultImageId: {
      type: 'text',
      value: defaultImageId,
    },
    disabledForNotLoggedIn: {
      type: 'checkbox',
      label: 'Disabled for guest users',
      ...(disabledForNotLoggedIn && {
        checked: disabledForNotLoggedIn.toString(),
        value: disabledForNotLoggedIn,
      }),
    },
    hiddenFromNotLoggedIn: {
      type: 'checkbox',
      label: 'Hidden from guest users',
      ...(hiddenFromNotLoggedIn && {
        checked: hiddenFromNotLoggedIn.toString(),
        value: hiddenFromNotLoggedIn,
      }),
    },
    moq: {
      type: 'number',
      label: 'MOQ',
      value: moq,
    },
    name: {
      type: 'text',
      value: name,
      rules,
    },
    newCustomField: {
      value: undefined,
    },
    orderProcess: {
      type: 'select',
      label: 'Order Process',
      value: orderProcess,
      rules,
    },
    productCode: {
      type: 'text',
      label: 'Product Code',
      value: productCode,
      rules,
    },
    productNature: {
      type: 'text',
      label: 'Product Nature',
      value: productNature,
      rules,
    },
    productSeries: {
      type: 'select',
      label: 'Product Series',
      value: productSeries,
      rules,
    },
    productType: {
      type: 'select',
      label: 'Product Type',
      value: productType,
      rules,
    },
    shippingMethod: {
      type: 'select',
      label: 'Shipping Method',
      value: shippingMethod,
      validators: [fieldData => requiredIfProductNature(fieldData, [PRODUCT_NATURE_PHYSICAL])],
    },
    shippingType: {
      type: 'select',
      label: 'Shipping Type',
      value: shippingType && { value: shippingType, label: shippingType },
      validators: [fieldData => requiredIfProductNature(fieldData, [PRODUCT_NATURE_PHYSICAL])],
    },
    shortDescription: {
      value: shortDescription,
    },
    storeDisplayInd: {
      type: 'switch',
      label: 'Store Display',
      value: storeDisplayInd,
    },
    permission: {
      fields: {
        availableCustomers: {
          type: 'array',
          label: 'Available Customers',
          value: permission.availableCustomers
            ? permission.availableCustomers.map(item => ({
                ...item,
                customerLabel:
                  item.partnerId && item.partnerId !== '0' ? `${item.name} (${item.partnerId})` : item.name,
              }))
            : [],
          placeholder: 'Select Customer',
        },
        availableStores: {
          type: 'array',
          label: 'Available Stores',
          value: permission.availableStores || [],
          placeholder: 'Select Store',
        },
        disableCustomerGroups: {
          type: 'select',
          label: 'Disable Customer Groups',
          value: permission.disableCustomerGroups || [],
          placeholder: 'Select Customer Group',
        },
        hidePriceFromCustomerGroups: {
          type: 'select',
          label: 'Hide price in storefront from these customer groups',
          value: permission.hidePriceFromCustomerGroups || [],
          placeholder: 'Select Customer Group',
        },
        excludedCountries: {
          type: 'array',
          label: 'Excluded Countries',
          value: permission.excludedCountries || [],
          placeholder: 'Select Country',
        },
        visibleOnlyAsCustomOptionInd: {
          type: 'switch',
          label: 'Visible Only As Custom Option',
          value: permission.visibleOnlyAsCustomOptionInd,
        },
        autoRenewInd: {
          type: 'switch',
          label: 'Allow Auto Renew',
          value: permission.autoRenewInd,
        },
        paymentAccount: {
          type: 'switch',
          label: 'Use Singapore Accounts',
          value: permission.paymentAccount === 'SG',
        },
        referralProductInd: {
          type: 'switch',
          label: 'Referral Program Enabled',
          value: permission.referralProductInd === true,
        },
        iccidBasedInd: {
          type: 'switch',
          label: 'ICCID based/New eSIM',
          value: permission.iccidBasedInd === true,
        },
        esimAllowCustomOptionInd: {
          type: 'switch',
          label: 'eSIM allows custom option',
          value: permission.esimAllowCustomOptionInd === true,
        },
      },
    },
    ministorePermission: {
      fields: {
        availableCustomers: {
          type: 'array',
          label: 'Available Customers',
          value: ministorePermission.availableCustomers
            ? ministorePermission.availableCustomers.map(item => ({
                ...item,
                customerLabel:
                  item.partnerId && item.partnerId !== '0' ? `${item.name} (${item.partnerId})` : item.name,
              }))
            : [],
          placeholder: 'Select Customer',
        },
        availableStores: {
          type: 'array',
          label: 'Available Stores',
          value: ministorePermission.availableStores || [],
          placeholder: 'Select Store',
        },
        disableCustomerGroups: {
          type: 'select',
          label: 'Disable Customer Groups',
          value: ministorePermission.disableCustomerGroups || [],
          placeholder: 'Select Customer Group',
        },
        hidePriceFromCustomerGroups: {
          type: 'select',
          label: 'Hide price in storefront from these customer groups',
          value: ministorePermission.hidePriceFromCustomerGroups || [],
          placeholder: 'Select Customer Group',
        },
        excludedCountries: {
          type: 'array',
          label: 'Excluded Countries',
          value: ministorePermission.excludedCountries || [],
          placeholder: 'Select Country',
        },
      },
    },
    prices: {
      type: 'array',
    },
    allStoresStockAvailability: {
      type: 'select',
      value: null,
    },
    tags: {
      label: 'Tags',
      type: 'select',
      value: tags.map(item => ({ value: item, label: item })),
      placeholder: 'To add tags, press tab or enter key',
    },
    timePeriod: {
      type: isPhysicalProduct ? 'select' : 'number',
      label: isPhysicalProduct ? 'Time Period' : '',
      value: timePeriod,
      validators: [validateTimePeriod],
    },
    timePeriodUnit: {
      type: 'select',
      placeholder: 'time unit',
      value: timePeriodUnit,
      validators: [validateTimePeriodUnit],
    },
    upc: {
      type: 'text',
      label: 'Unique Product Code',
      value: upc,
    },
    flexibleCustomOptionCategory: {
      label: 'Categories',
      value: flexibleCustomOptionCategory,
    },
    newCustomOptionCategories: {
      value: [],
    },
    searchTag: {
      value: searchTag,
    },
  }

  return defaultFields
}

const getCustomFields = attributes => {
  return attributes && attributes.length
    ? sortByNumber(attributes, 'sortOrder').reduce((fields, item) => {
        const field = {
          label: item.attributeLabel,
          value: item.attributeValue,
        }

        switch (item.attributeName) {
          case ATTRIBUTE_NAMES.VIRTUALPRODUCTTYPE:
            field.validators = [formData => [!requireVirtualProductType(formData.form), `${field.label} is required`]]
            break
          case ATTRIBUTE_NAMES.SFCRENEWALQUOTA:
          case ATTRIBUTE_NAMES.SFCVOLUME:
            field.validators = [
              formData => [!requireDataPlanAttribute(formData.form, field.key), `${field.label} is required`],
            ]
            break
          default:
            break
        }

        fields[item.attributeName] = field
        return fields
      }, {})
    : null
}

const requiredIfProductNature = (fieldData, expectedType = []) => {
  const { field, form } = fieldData
  if (!field.value) {
    const productNature = form.$('productNature').value ? form.$('productNature').value.value : PRODUCT_NATURE_PHYSICAL
    return [!expectedType.includes(productNature), `${field.label} field is required.`]
  }
  return [true]
}

const requireAttributes = ({ form }) => {
  const errors = []
  if (requireVirtualProductType(form)) {
    errors.push('Attribute "virtual product type" is required for product with product nature "virtual"')
  }

  if (errors.length) {
    return [false, errors.join('\n')]
  }
  return [true]
}

export const requireVirtualProductType = form => {
  const {
    productNature = {},
    customFieldsValidator,
    customFields: { virtualProductType },
  } = form.values()
  if (
    productNature &&
    [
      PRODUCT_NATURE_VIRTUAL,
      PRODUCT_NATURE_VIRTUAL_NO_SN,
      PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
      PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID,
    ].includes(productNature.value)
  ) {
    if (virtualProductType?.value === VIRTUAL_PRODUCT_TYPE.LICENSE) {
      form.$('timePeriod').resetValidation()
      form.$('timePeriodUnit').resetValidation()
    }

    if (customFieldsValidator) {
      return !virtualProductType
    }
    return !virtualProductType || !virtualProductType.value
  }
  return false
}

export const requireDataPlanAttribute = (form, attributeName) => {
  const { productNature = {}, customFields = {}, customFieldsValidator, permission = {} } = form.values()
  const { virtualProductType } = customFields

  if (
    permission.autoRenewInd &&
    productNature &&
    [PRODUCT_NATURE_VIRTUAL, PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID, PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID].includes(
      productNature.value
    )
  ) {
    if (
      (customFieldsValidator && virtualProductType === VIRTUAL_PRODUCT_TYPE.DATAPLANLICENSE) ||
      (virtualProductType && virtualProductType.value === VIRTUAL_PRODUCT_TYPE.DATAPLANLICENSE)
    ) {
      const attribute = customFields[attributeName]
      return !attribute || attribute === '0'
    }
  }
  return false
}

const validateTimePeriod = fieldData => {
  const { field, form } = fieldData

  const { productNature = {}, customFields } = form.values()
  const productNatureValue = productNature?.value || PRODUCT_NATURE_PHYSICAL

  if (productNatureValue !== PRODUCT_NATURE_PHYSICAL) {
    let error = ''
    const virtualProductType = customFields?.virtualProductType?.value || customFields?.virtualProductType

    if (virtualProductType !== VIRTUAL_PRODUCT_TYPE.LICENSE) {
      if (field.value >= 1) {
        const timePeriodUnit = form.$('timePeriodUnit').value?.value
        switch (timePeriodUnit) {
          case 'days':
            if (field.value > 31) {
              error = 'Time Period can not exceeed 31 days'
            }
            break
          case 'weeks':
            if (field.value > 4) {
              error = 'Time Period can not exceeed 4 weeks'
            }
            break
          case 'months':
            if (field.value > 11) {
              error = 'Time Period can not exceeed 11 months'
            }
            break
        }
      } else if (!field.value || field.value < 1) {
        error = 'Time Period value must be at least 1 for a non-license product'
      }
    }

    if (error) {
      return [false, error]
    }
  }

  return [true]
}

const validateTimePeriodUnit = fieldData => {
  const { field, form } = fieldData

  const productNature = form.$('productNature').value ? form.$('productNature').value.value : PRODUCT_NATURE_PHYSICAL
  if (productNature !== PRODUCT_NATURE_PHYSICAL) {
    let error = ''
    const virtualProductType = form.$('customFields').value?.virtualProductType?.value

    if (virtualProductType !== VIRTUAL_PRODUCT_TYPE.LICENSE) {
      if (!field.value?.value) {
        const timePeriod = form.$('timePeriod').value

        if (!timePeriod) {
          error = 'Time Period Unit is required for non-license products'
        }
      }
    }

    if (error) {
      return [false, error]
    }
  }

  return [true]
}

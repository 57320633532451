/* @flow */
import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { ACTIVE_STATUS, BANK_ACCOUNTS_PATH, INACTIVE_STATUS } from 'definitions'
import { allowedTo } from 'helpers/Auth'
import { isNull } from 'helpers/Common'
import { titleize } from 'helpers/String'
import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import InputText from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'

class BankAccountForm extends Component {
  constructor(props) {
    super(props)

    const {
      data: {
        name,
        bankName,
        bankAddress,
        accountName,
        accountNo,
        swiftNumber,
        routingNumber,
        ibanNumber,
        description,
        status = ACTIVE_STATUS,
        vatInd = false,
        countries,
      } = {},
    } = props
    const rules = 'required'
    const fields = {
      name: {
        label: 'Name',
        rules,
        value: name,
      },
      bankName: {
        label: 'Bank Name',
        rules,
        value: bankName,
      },
      bankAddress: {
        label: 'Bank Address',
        value: bankAddress,
      },
      accountName: {
        label: 'Account Name',
        rules,
        value: accountName,
      },
      accountNo: {
        label: 'Account No',
        rules,
        value: accountNo,
      },
      swiftNumber: {
        label: 'Swift No',
        value: swiftNumber,
      },
      routingNumber: {
        label: 'Routing No',
        value: routingNumber,
      },
      ibanNumber: {
        label: 'IBAN No',
        value: ibanNumber,
      },
      description: {
        type: 'textarea',
        label: 'Description',
        value: description,
        rules: 'max:500',
      },
      status: {
        type: 'switch',
        label: titleize(status),
        value: status === ACTIVE_STATUS,
      },
      vatInd: {
        type: 'switch',
        value: vatInd,
        label: 'VAT',
      },
      countries: {
        value: countries,
        label: 'Countries',
      },
    }

    this.form = new Form({ fields }, { name: 'BankAccountForm', handleSubmit: this.handleSubmit })
    this.saving = false
  }

  componentDidMount() {
    this.props.commonRepository.getCommon('countries', 'countries')
  }

  handleSubmit = () => {
    const actualValues = this.form.values()
    const {
      bankAccountRepository: {
        create,
        patch,
        CRUD: {
          data: { id = null },
        },
      },
      isAdd,
    } = this.props

    const requestAction = isAdd ? create : patch
    this.saving = true
    requestAction(
      {
        ...actualValues,
        id,
        status: actualValues.status ? ACTIVE_STATUS : INACTIVE_STATUS,
        countries: isNull(actualValues.countries),
      },
      result => result && this.props.history.push(BANK_ACCOUNTS_PATH)
    )
  }

  backToIndex = () => {
    this.props.history.push(BANK_ACCOUNTS_PATH)
  }

  render() {
    const {
      commonRepository: {
        Common: { countries: { data: countries = [] } = {} },
      },
      bankAccountRepository: { CRUD: { data: { id }, errors, loading } = {} },
    } = this.props

    return (
      <Row>
        <Col xl={6}>
          <FormContainer
            title="Bank Account Detail"
            onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
            errors={errors}
            actions={
              <FormActions
                variant="contained"
                loading={loading}
                loadingMessage={`${this.saving ? 'Saving' : 'Loading'} please wait...`}
                confirmLabel="Save"
                cancelLabel={id ? 'Close' : 'Cancel'}
                onCancel={this.backToIndex}
                hideConfirm={!allowedTo()}
              />
            }
          >
            <InputText
              field={this.form.$('name')}
              endAdornment={<Checkbox field={this.form.$('status')} plain className="ml-1 mt-4" id="checkboxStatus" />}
            />
            <InputText field={this.form.$('bankName')} />
            <InputText field={this.form.$('bankAddress')} />
            <Row>
              <Col lg={6}>
                <InputText field={this.form.$('accountName')} />
              </Col>
              <Col lg={6}>
                <InputText field={this.form.$('accountNo')} />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <InputText field={this.form.$('swiftNumber')} />
              </Col>
              <Col lg={4}>
                <InputText field={this.form.$('routingNumber')} />
              </Col>
              <Col lg={4}>
                <InputText field={this.form.$('ibanNumber')} />
              </Col>
            </Row>
            <Checkbox field={this.form.$('vatInd')} id="checkboxVatInd" />
            <ReactSelect
              field={this.form.$('countries')}
              options={{
                options: countries,
                isMulti: true,
              }}
              showTooltip
            />
            <InputText field={this.form.$('description')} rows="4" />
          </FormContainer>
        </Col>
      </Row>
    )
  }
}

export default decorate(inject('bankAccountRepository', 'commonRepository')(observer(BankAccountForm)), {})

import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { PRODUCT_NATURE_VIRTUAL, PRODUCT_PERMISSION_FIELDS } from 'definitions'
import CardField from './CardField'
import Checkbox from 'components/Common/Form/Checkbox'

class PermissionsPanel extends Component {
  componentDidMount() {
    this.initializeLabels()
  }
  initializeLabels = () => {
    const { commonRepository, data, form } = this.props

    if (data.productNature.value === PRODUCT_NATURE_VIRTUAL) {
      const {
        Common: { productPermissionLabels: { data: productPermissionLabels = [] } = {} },
      } = commonRepository
      if (productPermissionLabels?.length) {
        const iccidBasedIndCollection = productPermissionLabels.find(
          item => item.value === PRODUCT_PERMISSION_FIELDS.iccidBasedInd
        )
        if (iccidBasedIndCollection) {
          form.$('permission.iccidBasedInd').set('label', iccidBasedIndCollection.label)
        }

        const esimAllowCustomOptionIndCollection = productPermissionLabels.find(
          item => item.value === PRODUCT_PERMISSION_FIELDS.esimAllowCustomOptionInd
        )
        if (esimAllowCustomOptionIndCollection) {
          form.$('permission.esimAllowCustomOptionInd').set('label', esimAllowCustomOptionIndCollection.label)
        }
      }
    }
  }
  render() {
    const { storeRepository, commonRepository, customerGroupRepository, customerRepository, data, form, readOnly } =
      this.props

    return (
      <React.Fragment>
        <CardField
          elementKey="productAvailableStores"
          search={storeRepository.reactSelectSearch}
          field={form.$('permission.availableStores')}
          readOnly={readOnly}
          valueKey="id"
          labelKey="name"
        />
        <CardField
          elementKey="productExcludedCountries"
          search={params => commonRepository.reactSelectSearch(params, 'country')}
          field={form.$('permission.excludedCountries')}
          readOnly={readOnly}
        />
        <CardField
          elementKey="productDisableCustomerGroups"
          search={params => customerGroupRepository.reactSelectSearch({ ...params, sort: 'name,asc' })}
          field={form.$('permission.disableCustomerGroups')}
          readOnly={readOnly}
          valueKey="id"
          labelKey="name"
        />
        <CardField
          elementKey="productHidePriceFromCustomerGroups"
          search={params => customerGroupRepository.reactSelectSearch({ ...params, sort: 'name,asc' })}
          field={form.$('permission.hidePriceFromCustomerGroups')}
          readOnly={readOnly}
          valueKey="id"
          labelKey="name"
        />
        <CardField
          search={customerRepository.customerReactSelectSearch}
          field={form.$('permission.availableCustomers')}
          readOnly={readOnly}
          valueKey="id"
          labelKey="customerLabel"
        />
        <Checkbox
          field={form.$('permission.visibleOnlyAsCustomOptionInd')}
          className="pl-4"
          readOnly={readOnly}
          disabled={readOnly}
        />
        <Checkbox field={form.$('permission.autoRenewInd')} className="pl-4" readOnly={readOnly} disabled={readOnly} />
        <Checkbox
          field={form.$('permission.paymentAccount')}
          className="pl-4"
          readOnly={readOnly}
          disabled={readOnly}
        />
        {data.productNature.value === PRODUCT_NATURE_VIRTUAL && (
          <React.Fragment>
            <Checkbox
              field={form.$('permission.iccidBasedInd')}
              className="pl-4"
              readOnly={readOnly}
              disabled={readOnly}
            />
            <Checkbox
              field={form.$('permission.esimAllowCustomOptionInd')}
              className="pl-4"
              readOnly={readOnly}
              disabled={readOnly}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default inject(
  'storeRepository',
  'commonRepository',
  'customerGroupRepository',
  'customerRepository'
)(observer(PermissionsPanel))

import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import { formatSerialNumber } from 'helpers/Formatters'
import { FormGroup } from 'reactstrap'
import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import styled from 'styled-components'

import EsimDataPlanModal from './EsimDataPlanModal'
import SfcCloudUnlimitedModal from './SfcCloudUnlimitedModal'

class SerialNumbersModal extends Component {
  constructor(props) {
    super(props)

    const {
      selectedOrderItem: { serialNumbers },
    } = props

    this.state = {
      openSfcModal: false,
      openEsimModal: false,
    }

    const fields = {
      serialNumbers: {
        label: 'Serial Numbers',
        value: serialNumbers ? serialNumbers.map(i => i.serialNumber).join('\n') : null,
        rules: 'required',
        placeholder: 'Use ENTER key to add multiple serial numbers',
      },
    }

    this.form = new Form({ fields }, { name: 'OrderItemSerialNumber', handleSubmit: this.handleSubmit })
  }

  componentWillUnmount() {
    this.props.orderItemSerialNumberRepository.clearCrudData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.sfcPlanDetails !== prevProps.sfcPlanDetails) {
      this.setState({ openSfcModal: !!this.props.sfcPlanDetails?.length })
    }
    if (this.props.esimDetails !== prevProps.esimDetails) {
      this.setState({ openEsimModal: !!this.props.esimDetails?.length })
    }
  }

  formatSerialNumbers = () => {
    const serialNumbers = this.form
      .$('serialNumbers')
      .values()
      .trim()
      .split('\n')
      .map(serialNumber => formatSerialNumber(serialNumber))
      .join('\n')

    this.form.$('serialNumbers').set(serialNumbers)
  }

  handleSubmit = proceed => {
    const { serialNumbers } = this.form.values()
    const { handleSubmit } = this.props

    handleSubmit(serialNumbers, proceed)
  }

  triggerSubmit = () => {
    this.buttonSubmit.click()
  }

  toggleSfcModal = () => this.setState(prev => ({ openSfcModal: !prev.openSfcModal }))

  toggleEsimModal = () => this.setState(prev => ({ openEsimModal: !prev.openEsimModal }))

  render() {
    const {
      orderItemSerialNumberRepository: {
        CRUD: { errors, loading, submitting },
      },
      open,
      onClose,
      sfcPlanDetails,
      esimDetails,
      readOnly,
    } = this.props
    const { openSfcModal, openEsimModal } = this.state

    return (
      <React.Fragment>
        <Modal
          title={readOnly ? 'Serial Numbers' : 'Enter Serial Numbers'}
          loading={loading}
          open={open}
          closeLabel={readOnly ? 'Close' : 'Cancel'}
          hideConfirm={!allowedToOrderRole() || readOnly}
          confirmLabel="OK"
          onConfirm={this.triggerSubmit}
          onClose={onClose}
        >
          <FormContainer
            onSubmit={e => {
              e.stopPropagation()
              if (!readOnly) {
                this.formatSerialNumbers()
                this.form.onSubmit(e, { onSuccess: () => this.handleSubmit() })
              }
            }}
            errors={errors}
            plain
          >
            <FormGroupStyled>
              <Input
                field={this.form.$('serialNumbers')}
                type="textarea"
                rows="10"
                onBlur={() => {
                  !readOnly && this.formatSerialNumbers()
                }}
                readOnly={readOnly || submitting}
              />
            </FormGroupStyled>
            <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
          </FormContainer>
        </Modal>
        {openSfcModal && (
          <SfcCloudUnlimitedModal
            open
            sfcPlanDetails={sfcPlanDetails}
            onClose={this.toggleSfcModal}
            onSubmit={() => {
              this.toggleSfcModal()
              this.handleSubmit(true)
            }}
          />
        )}
        {openEsimModal && (
          <EsimDataPlanModal
            open
            esimDetails={esimDetails}
            onClose={this.toggleEsimModal}
            onSubmit={() => {
              this.toggleEsimModal()
              this.handleSubmit(true)
            }}
          />
        )}
      </React.Fragment>
    )
  }
}

const FormGroupStyled = styled(FormGroup)`
  &.form-group {
    textarea.form-control {
      min-height: 150px;
      height: ${({ nolabel, haserror }) => (nolabel ? (haserror ? 'calc(100% - 15px)' : '100%') : 'auto')};
    }
  }
`

export default decorate(inject('orderItemSerialNumberRepository')(observer(SerialNumbersModal)), {})

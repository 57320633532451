/* @flow */
// import { jsonToQueryString } from 'helpers/Formatters'
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class OrderServices extends BaseServices {
  api: APIRepository
  publicAPI: APIRepository
  constructor(api: APIRepository, publicAPI: APIRepository) {
    const url = '/orders'
    super(api, url)
    this.api = api
    this.publicAPI = publicAPI
    this.url = url
    this.publicUrl = `public${url}`
  }

  cancelOrder(orderId) {
    return this.api.patch(`${this.url}/${orderId}/cancel`)
  }

  cloneOrder(orderId) {
    return this.api.post(`${this.url}/${orderId}/clone`)
  }

  createDraftOrder(payload) {
    return this.api.post(`${this.url}/new`, payload)
  }

  computeOrderRates(orderId) {
    return this.api.get(`${this.url}/compute/${orderId}`)
  }

  submitAsOrder(orderId) {
    return this.api.post(`${this.url}/${orderId}/convert`)
  }

  removeDiscount(orderId, payload) {
    return this.api.patch(`${this.url}/${orderId}/removeDiscounts`, payload)
  }

  sendApprovalRequest(orderId, payload, isQuotation = false) {
    return this.api.patch(`${this.url}/${orderId}/sendRequest${isQuotation ? `?quotationInd=true` : ''}`, payload)
  }

  applyCoupon(orderId, payload) {
    return this.api.post(`${this.url}/${orderId}/coupon`, payload)
  }

  removeCoupon(orderId, payload) {
    return this.api.patch(`${this.url}/${orderId}/coupon`, payload)
  }

  approveDiscount(orderId) {
    return this.api.get(`${this.url}/${orderId}/approveDiscount`)
  }

  rejectRequest(payload, orderId) {
    return this.api.patch(`${this.url}/${orderId}/rejectRequest`, payload)
  }

  getStatus(status) {
    return this.api.get(`${this.url}/status/${status}`)
  }

  updateStatus(orderId, payload) {
    return this.api.patch(`${this.url}/${orderId}/status/`, payload)
  }

  getDiscounts(orderId) {
    return this.api.get(`${this.url}/${orderId}/discounts`)
  }

  patchQuotation(orderId, payload) {
    return this.api.patch(`${this.url}/${orderId}/quotation/`, payload)
  }

  orderSummary(payload) {
    return this.api.post(`${this.url}/summary`, payload)
  }

  getPricingRulesOptions(order) {
    return this.api.patch(`${this.url}/${order.id}/pricingRules/available`, order)
  }

  saveAsDraft(orderId, payload) {
    return this.api.update(`${this.url}/${orderId}`, payload)
  }

  saveOrder(orderId, payload) {
    return this.api.patch(`${this.url}/pending/${orderId}`, payload)
  }

  sendProformaInvoice(orderId) {
    return this.api.post(`${this.url}/${orderId}/invoice`)
  }

  sendEmail(orderId) {
    return this.api.post(`${this.url}/${orderId}/email`)
  }

  rejectRequestApproval(token, payload) {
    return this.publicAPI.post(`${this.publicUrl}/reject/${token ? `?emailToken=${token}` : ''}`, payload)
  }

  approveRequestApproval(token) {
    return this.publicAPI.post(`${this.publicUrl}/approve${token ? `?emailToken=${token}` : ''}`)
  }

  getOrderByOrderNumber = async orderNumber => {
    return this.api.get(`${this.url}/orderNumber/${orderNumber}`)
  }

  convertCoTermQuotation(id) {
    return this.api.post(`/co-term/quotations/${id}/convert`)
  }
}

export default OrderServices

export class OrderItemServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/orderItems'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchTable(params, id) {
    return this.api.get(`${this.url}/order/${id}`, params)
  }

  create(payload, orderId) {
    return this.api.post(`${this.url}/${orderId}`, payload)
  }

  patchOrderItem(payload, orderItemId) {
    return this.api.patch(`${this.url}/${orderItemId}`, payload)
  }

  patchDiscount(payload, orderId) {
    return this.api.patch(`${this.url}/discount/${orderId}`, payload)
  }

  applyCoupon(orderItemId, payload) {
    return this.api.post(`${this.url}/${orderItemId}/coupon`, payload)
  }

  removeCoupon(orderItemId) {
    return this.api.del(`${this.url}/${orderItemId}/coupon`)
  }

  applyPricingRule(orderId, payload) {
    return this.api.post(`${this.url}/order/${orderId}/pricingRule`, payload)
  }

  removePricingRule(orderId, payload) {
    return this.api.patch(`${this.url}/order/${orderId}/removePricingRule`, payload)
  }

  removeOrderItem(orderId, payload) {
    return this.api.patch(`${this.url}/order/${orderId}/removeOrderItem`, payload)
  }

  validateOrganizationNumbers(payload) {
    return this.api.patch(`${this.url}/validate/orgNumber`, payload)
  }
}

export class OrderAttributeServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/orders/attributes'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchAll() {
    return this.api.get(this.url)
  }

  getAttributeValue(payload) {
    return this.api.get(`${this.url}/values`, payload)
  }
}

export class OrderItemSerialNumberServices extends OrderItemServices {
  patch(payload, orderItemId) {
    return this.api.patch(`${this.url}/order/${orderItemId}/serialNumber`, payload)
  }
  put(payload) {
    const { id, ...rest } = payload
    return this.api.update(`${this.url}/order/${id}/serialNumber`, rest)
  }
  getSerialNumbers(payload, orderId) {
    return this.api.get(`${this.url}/order/${orderId}/serialNumbers`, payload)
  }
  updateStatus(payload, orderId) {
    return this.api.update(`${this.url}/order/${orderId}/serialNumber/status`, payload)
  }
  bulkUpdateStatus(payload, orderId) {
    return this.api.update(`${this.url}/order/${orderId}/serialNumbers/status`, payload)
  }
}

export class OrderItemCustomOptionServices extends OrderItemServices {
  patch(payload, orderItemId) {
    return this.api.patch(`${this.url}/${orderItemId}/customOption`, payload)
  }
}

export class OrderAdvanceSearchServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/orders/advance-search'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchTable(payload) {
    return this.api.post(this.url, payload)
  }
}
